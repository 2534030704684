import '@/index.css';

import React from 'react';
import {createRoot} from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from '@/containers/App';
import reportWebVitals from '@/reportWebVitals';

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);

root.render(<BrowserRouter>
  <Routes>
    <Route path={'/'} element={<App />} />
  </Routes>
</BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
