import './index.scss';

import React, {useEffect} from 'react';

const getToken = (redirectUrl: string) => {
  try {
    // @ts-ignore
    if (document.getElementsByTagName('iframe')[0].contentWindow!.getOauthCode) {
      // @ts-ignore
      const code = document.getElementsByTagName('iframe')[0].contentWindow!.getOauthCode();
      // 从这里将code跳转回到原来的页面上 todo 需要测试转义后的url，参数是否会丢失的问题
      redirectUrl = decodeURIComponent(redirectUrl);
      const urlDomain = redirectUrl.split('?')[0];
      const paramStr = genParams({ ...getUrlParams(redirectUrl), code: code });
      window.location.replace(`${urlDomain}?${paramStr}`);
    } else {
      wait(500).then(() => getToken(redirectUrl));
    }
  } catch (e) {
    wait(500).then(() => getToken(redirectUrl));
  }
};

const wait = (time: number) => {
  return new Promise<void>(resolve => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      resolve();
    }, time);
  });
};

const genParams = (data: any) => {
  const arr = [];
  for (const i in data) {
    if (data.hasOwnProperty(i)) {
      if (data[i] instanceof Array) {
        data[i] = JSON.stringify(data[i]);
      }
      arr.push(encodeURIComponent(i) + '=' + encodeURIComponent(data[i]));
    }
  }
  return arr.join('&');
};

const getUrlParams = (url: string): { [name: string]: string } => {
  let reg_url = /^[^\?]+\?([\w\W]+)$/,
    reg_para = /([^&=]+)=([\w\W]*?)(&|$|#)/g,
    arr_url = reg_url.exec(url),
    ret: { [name: string]: string } = {};
  if (arr_url && arr_url[1]) {
    let str_para = arr_url[1],
      result;
    while ((result = reg_para.exec(str_para)) != null) {
      ret[result[1]] = result[2];
    }
  }
  return ret;
};

function App() {
  useEffect(() => {
    const params = getUrlParams(window.location.href);
    // 如果域名不是gaotengtech.com就自动跳转到gaotengtech.com
    if (window.location.host !== 'oauth.gaotengtech.com') {
      window.location.replace(`https://oauth.gaotengtech.com?${genParams(params)}`);
    }
    if (params.hasOwnProperty('code')) {
      let redirectUrl = params['redirect_url'];
      redirectUrl = decodeURIComponent(redirectUrl);
      const urlDomain = redirectUrl.split('?')[0];
      delete params['redirect_url'];
      const paramStr = genParams(params);
      window.location.replace(`${decodeURIComponent(urlDomain)}?${paramStr}`);
      return;
    }

    // 登陆这里选择是跳转到哪里
    const ua = window.navigator.userAgent.toLowerCase();
    const uaMatch = ua.match(/wxwork/i);
    if (uaMatch && uaMatch[0] === 'wxwork') {
      window.location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww5aa8baaf11d01f3c&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_base&state=STATE&agentid=1000154#wechat_redirect`
      );
      return;
    } else {
      //todo 换成jsapi调用登录
      //@ts-ignore
      const wwLogin = new WwLogin({
        id: 'wx_reg',
        appid: 'ww5aa8baaf11d01f3c',
        agentid: '1000154',
        redirect_uri: encodeURIComponent(window.location.href),
        href: '',
        self_redirect: false
      });
    }
  }, []);

  return (
    <div className="pi-pc-c">
      <div id="wx_reg" className="pi-pc" />
    </div>
  );
}

export default App;
